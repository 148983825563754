import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Grocery App Template | Grocery App Design | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/grocery-delivery-mobile-app/"
    metaDescription="Looking to design your grocery app for mobile devices? Our brand-new grocery app template is here to supercharge your design project."
    description="
    h2:Say hello to ontheshelf, our mobile grocery delivery app design template
    <br/>
    Looking to design your own grocery app for mobile devices? Maybe you want to deliver your produce directly to your customers' doors, but you don't know where to begin when it comes to app and UI design? Our brand-new grocery app template is here to supercharge your design project.
    <br/>
    h3:Our new template is fresh and will never go out of date
    <br/>
    You don't need to be an expert designer to use our grocery delivery app template, simply sign up to Uizard Pro, navigate to our extensive list of <a:https://uizard.io/templates/mobile-app-templates/>mobile app design templates</a> and create a new project using ontheshelf. Customization is super easy in Uizard, meaning you can use our template straight out of the box and pass it on to a developer, or you can edit and customize it to fit with your brand vision before hand-off.
    <br/>
    h3:Don't shop alone, invite your team along
    <br/>
    Drawn-out design flows and protracted <a:https://uizard.io/prototyping/>prototyping</a> are now a thing of the past. You can easily collaborate and iterate with your team using Uizard's smart sharing features. Simply share your project link with anyone you want to add to your team, and they can view and comment on your design. You can even share your ideas with potential customers!
    "
    pages={[
      "A clean and simple log-in screen",
      "Grocery store shopping pages with product lists, categories, and a favorites screen",
      "Product display pages with add-to-basket CTA",
      "Basket and order details screens to complete your user journey",
    ]}
    projectCode="JJxOWvdEMefgOn85Gz8B"
    img1={data.image1.childImageSharp}
    img1alt="mobile grocery delivery app design overview"
    img2={data.image2.childImageSharp}
    img2alt="mobile grocery delivery app design homepage screen"
    img3={data.image3.childImageSharp}
    img3alt="mobile grocery delivery app design delivery screen"
    img4={data.image4.childImageSharp}
    img4alt="mobile grocery delivery app design food categories screen"
    img5={data.image5.childImageSharp}
    img5alt="mobile grocery delivery app design summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/grocery-delivery-mobile-app/grocery-app-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/grocery-delivery-mobile-app/grocery-app-homepage.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/grocery-delivery-mobile-app/grocery-app-delivery.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/grocery-delivery-mobile-app/grocery-app-categories.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/grocery-delivery-mobile-app/grocery-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
